import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

export const DynamicGraph = ({nameYAxis="Y-Axis", nameXAxis="X-Axis"}) => {
  const svgRef = useRef();
  let audioContext;

  useEffect(() => {
    const width = 340;
    const height = 350;
    const margin = { top: 20, right: 20, bottom: 50, left: 50 };

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    const epsilon = 1;
    const sigma = 1;

    const distances = d3.range(0.8, 3, 0.01);
    const potentialValues = distances.map((r) => {
      return 4 * epsilon * (Math.pow(sigma / r, 12) - Math.pow(sigma / r, 6));
    });

    const xScale = d3
      .scaleLinear()
      .domain([0.8, 3])
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([-1, 1])
      .range([height - margin.bottom, margin.top]);

    const line = d3
      .line()
      .x((d, i) => xScale(distances[i]))
      .y((d) => yScale(d));

    svg
      .append("path")
      .datum(potentialValues)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 2)
      .attr("d", line);

    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale);

    svg
      .append("g")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(xAxis);

    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(yAxis);

    // Add zero level line
    svg
      .append("line")
      .attr("x1", margin.left)
      .attr("y1", yScale(0))
      .attr("x2", width - margin.right)
      .attr("y2", yScale(0))
      .attr("stroke", "black")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "4");

    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 20)
      .attr("x", -200)
      .style("text-anchor", "middle")
      .text(nameXAxis);

    svg
      .append("text")
      .attr("y", height - 10)
      .attr("x", width / 2)
      .style("text-anchor", "middle")
      .text(nameYAxis);

    // Initialize audio context
    audioContext = new (window.AudioContext || window.webkitAudioContext)();

    // Set up event listener to trigger sonification
    svgRef.current.addEventListener("mousemove", function (event) {
      // Get x and y coordinates of the mouse relative to the SVG element
      const rect = svgRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      // Map x and y coordinates to pitch and volume
      const pitch = xScale.invert(x);
      const volume = yScale.invert(y);

      // Play a note based on the mapped coordinates
      playNote(pitch, volume);
    });
  }, []);

  // Function to play a note
  function playNote(pitch, volume) {
    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);

    oscillator.type = "sine";
    oscillator.frequency.value = pitch * 100; // Adjust frequency
    gainNode.gain.value = volume * 0.5; // Adjust volume

    oscillator.start();
    oscillator.stop(audioContext.currentTime + 0.5); // Play for 0.5 seconds
  }

  return <svg ref={svgRef} viewBox="0 0 340 350"></svg>;
};
